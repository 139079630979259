import { ActionContext, Module } from 'vuex';
import { Getter, Action, Mutation } from './interface';
import {unref} from "vue";

interface AuthModule<T, R> {
    namespaced?: boolean;
    state?: T;
    getters?: { [key: string]: Getter<T, R> };
    actions?: { [key: string]: Action<T> };
    mutations?: { [key: string]: Mutation<T> };
    modules?: { [key: string]: Module<any, any> };
}


interface AuthState {
    loginStatus: boolean;
    token: string;
}

interface RootState {
    token: string;
}

const userModule: Module<AuthState, RootState> = {
    namespaced: true,
    state: {
        loginStatus: false,
        token: ""
    },
    getters: {
        token(state: AuthState): string {
            return state.token;
        },
        loginStatus(state: AuthState): boolean {

            return state.loginStatus;
        }
    },
    mutations: {
        setToken(state: AuthState, payload: string): void {
            state.token = payload;
        },
        setloginStatus(state: AuthState, payload: boolean): void {
            state.loginStatus = payload;
        },
    },
    actions: {
        async updateToken(context: ActionContext<AuthState, RootState>, token: string): Promise<void> {
            const { commit } = context;
            commit('setToken', token);
        },
        resetAuthStore() {
            // const { toLogin } = useRouterPush(false);
            // const { resetTabStore } = useTabStore();
            // const { resetRouteStore } = useRouteStore();
            // //解包unref
            // // const route = unref(router.currentRoute);
            //
            // clearAuthStorage();
            // this.$reset();
            //
            // if (route.meta.requiresAuth) {
            //     toLogin();
            // }
            //
            // nextTick(() => {
            //     resetTabStore();
            //     resetRouteStore();
            // });
        },
    }
}

export default userModule;
