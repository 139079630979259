<template>
  <router-view />
</template>

<script lang="ts" setup>
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --el-color-primary: #8851f1 !important;
  --el-color-info: #38bdf8 !important;
  --el-font-size-base: 12px !important;
  --el-font-family: JetBrainsSans !important;
  --el-font-weight-primary: 600 !important;
}

.el-menu-item * {
  vertical-align: middle !important;
}

.el-menu-item li {
  text-align: center !important;
  line-height: 20px;
}

.is-active {
  background-color: #8851f1 !important;
  color: #ffffff !important;
  font-weight: 500;
  // border-radius: 5px;
}

.el-menu-item:hover {
  background-color: #8851f1 !important;
}

.el-menu-item:hover * {
  color: #fff !important;
}

.el-menu-item:hover .el-icon-menu {
  color: #fff !important;
}

.el-menu-item:hover .el-icon-document {
  color: #fff !important;
}

.is-active .el-sub-menu__title {
  // border-top: 2px solid rgba($color: #8851f1, $alpha: 1.0) !important;
}

.el-dialog__body {
  padding: 0 !important;
}

.is-active>.el-sub-menu__title>i {
  color: #8851f1 !important;
}

.is-active>.el-sub-menu__title>span {
  color: #8851f1 !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  /* 设置滚动条宽度 */
}

::-webkit-scrollbar-thumb {
  background-color: #8851f1;
  /* 设置滚动条拖动手柄的颜色 */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 设置滚动条轨道的颜色 */
}
</style>
