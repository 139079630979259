import { ActionContext, Module} from 'vuex';
import {Getter, Action, Mutation} from './interface';

interface userModule<T, R> {
    namespaced?: boolean;
    state?: T;
    getters?: { [key: string]: Getter<T, R> };
    actions?: { [key: string]: Action<T> };
    mutations?: { [key: string]: Mutation<T> };
    modules?: { [key: string]: Module<any, any> };
}


interface UserState {
     user: {
         id: number;
         name: string;
         email: string;
         password: string;
         role: string;
         createdAt: string;
         updatedAt: string;
         deletedAt: string;
     };
}

interface RootState {
    version: string;
}

const userModule: Module<UserState, RootState> = {
    namespaced: true,
    state: {
        user: {
            id: 0,
            name: '',
            email: '',
            password: '',
            role: '',
            createdAt: '',
            updatedAt: '',
            deletedAt: '',
        },
    },
    getters: {
        user(state: UserState): any {
            return state.user;
        }
    },
    // 同步
    mutations: {
        updateUser(state: UserState, user: UserState['user']): void {
            state.user = user;
        }
    },
    // 异步
    actions: {
        updateUser(context: ActionContext<UserState, RootState>, user: any): void {
            context.commit('updateUser', user);
        }
    }
}

export default userModule;
