import { createStore, Store } from 'vuex'
import auth from "./modules/auth";
import user from "@/store/modules/user";
import route from  "@/store/modules/route";


/**
 * [store description]
 *
 * @return  {[type]}  [return description]
 */
export const store = createStore<any>({
  modules: {
    auth,
    user,
    route
  }
})