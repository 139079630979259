// 导入公共布局
import BasicLayout from '@/layout/basic-layout/index.vue';
import BlankLayout from '@/layout/blank-layout/index.vue';
import { RouteRecordRaw } from "vue-router";

function getLoginModuleRegExp() {
	const modules = [
		"pwd-login",
		"code-login",
		"register",
		"reset-pwd",
		"bind-wechat",
	];
	return modules.join("|");
}




/** 根路由, 用于用户直接访问进行跳转 */
export const ROOT_ROUTE: RouteRecordRaw = {
	name: "root",
	path: "/",
	children: [],
	redirect: "/dashborad",
	meta: {
		title: "Root",
		requiresAuth: true,
	},
};

/** 基本路由(公共布局均为blank)，通过指定路径进行跳转出来 */
export const constantRoutes: Array<RouteRecordRaw> = [
	ROOT_ROUTE,
	{
		name: "login",
		path: "/login",
		component: () => import("@/views/LoginView.vue"),
		props: (route: { params: { module: any } }) => {
			const moduleType = route.params.module || "pwd-login";
			return {
				module: moduleType,
			};
		},
		meta: {
			title: "登录",
			dynamicPath: `/login/:module(${getLoginModuleRegExp()})?`,
			singleLayout: "blank",
			requiresAuth: false,
		},
	},
	{
		name:"videoPlayer",
		path:"/videoPlayer",
		component:()=>import("@/views/VideoPlayerView.vue"),
		meta:{
			title:"视频播放",
			singleLayout:"blank",
			requiresAuth:false,
		}
	},
	{
		name: "notification",
		path: "/notification",
		component: () => import("@/views/NotificationView.vue"),
		meta: {
			title: "通知",
			singleLayout: "blank",
			requiresAuth: true,
		}
	},
	{
		name: "message",
		path: "/message",
		component: () => import("@/views/MessageView.vue"),
		meta: {
			title: "消息",
			singleLayout: "blank",
			requiresAuth: true,
		}
	},
	{
		name: '403',
		path: '/403',
		component: () => import("@/views/403.vue"),
		meta: {
			title: '无权限',
			singleLayout: 'blank',
			requiresAuth: true
		}
	},
	{
		name: '404',
		path: '/404',
		component: () => import("@/views/404.vue"),
		meta: {
			title: '未找到',
			singleLayout: 'blank',
			requiresAuth: true
		}
	},
	{
		name: '500',
		path: '/500',
		component: () => import("@/views/500.vue"),
		meta: {
			title: '服务器错误',
			singleLayout: 'blank',
			requiresAuth: true
		}
	},
	// 匹配无效路径的路由
	{
		name: 'not-found',
		path: '/:pathMatch(.*)*',
		component: () => import("@/views/Invalid.vue"),
		meta: {
			title: '未找到',
			singleLayout: 'blank',
			requiresAuth: true
		}
	}
];

// 菜单路由，可以通过菜单也扫描出来
export const MenuRoutes = [
	{
		name: "数据大屏",
		path: "/dashborad",
		redirect: "/dashboard/analysis",
		component: BasicLayout, //公共布局渲染出来
		children: [
			{
				name: "",
				path: "/dashboard/analysis",
				component: () => import("@/views/HomeView.vue"),
				meta: {
					icon: "",
					requiresAuth: true,
					i18nTitle: "routes.dashboard.analysis"
				},
			},
		],
		meta: {
			title: "数据中心",
			requiresAuth: true,
			icon: "bar-grid",
			i18nTitle: "routes.dashboard.analysis",
		}
	},
	{
		name: "视频内容管理",
		path: "/manage",
		component: BasicLayout, //公共布局渲染出来
		children: [
			{
				name: "新增视频",
				path: "/manage/create",
				component: () => import("@/views/CreateVideo.vue"),
				meta: {
					title: "新增视频",
					requiresAuth: true,
					icon: undefined,
					i18nTitle: "routes.dashboard.analysis",
				},
			},
			{
				name: "修改视频",
				path: "/manage/update",
				component: () => import("@/views/UpdateVideoView.vue"),
				meta: {
					title: "修改视频",
					requiresAuth: true,
					icon: undefined,
					i18nTitle: "routes.dashboard.monitor",
				},
			}
		],
		meta: {
			title: "指示中心",
			icon: "box-seam",
			requiresAuth: true,
		},
	},
	{
		name: "APP版本管理",
		path: "/app",
		component: BasicLayout, //公共布局渲染出来
		children: [
			{
				name: "版本管理",
				path: "/app/manage",
				component: () => import("@/views/ManageAppView.vue"),
				meta: {
					title: "版本管理",
					requiresAuth: true,
					icon: undefined,
					i18nTitle: "routes.dashboard.analysis",
				},
			},
			{
				name: "Upload管理",
				path: "/app/upload",
				component: () => import("@/views/AppUploadManageView.vue"),
				meta: {
					title: "上传app",
					requiresAuth: true,
					icon: undefined,
					i18nTitle: "routes.dashboard.monitor",
				},
			}
		],
		meta: {
			title: "指示中心",
			icon: "boxes",
			requiresAuth: true,
		},
	},
	// {
	// 	name: "dashboard",
	// 	path: "/dashboard",
	// 	redirect: "/dashboard/analysis",
	// 	component: BasicLayout, //公共布局渲染出来
	// 	children: [
	// 		{
	// 			name: "analysis",
	// 			path: "/dashboard/analysis",
	// 			component: () => import("@/views/HomeView.vue"),
	// 			meta: {
	// 				title: "分析页",
	// 				requiresAuth: true,
	// 				icon: "bar-chart",
	// 				i18nTitle: "routes.dashboard.analysis",
	// 			},
	// 		},
	// 		{
	// 			name: "monitor",
	// 			path: "/dashboard/monitor",
	// 			component: () => import("@/views/HomeView.vue"),
	// 			meta: {
	// 				title: "监控页",
	// 				requiresAuth: true,
	// 				icon: "eye",
	// 				i18nTitle: "routes.dashboard.monitor",
	// 			},
	// 		}
	// 	],
	// 	meta: {
	// 		title: "指示中心",
	// 		requiresAuth: true,
	// 	},
	// },
];
