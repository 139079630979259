import { ActionContext, Module} from 'vuex';
import {Getter, Action, Mutation} from './interface';

interface routeModule<T, R> {
    namespaced?: boolean;
    state?: T;
    getters?: { [key: string]: Getter<T, R> };
    actions?: { [key: string]: Action<T> };
    mutations?: { [key: string]: Mutation<T> };
    modules?: { [key: string]: Module<any, any> };
}


interface RouteState {
    isInitAuthRoute: boolean;
    isLogin: boolean;

}

interface RootState {
    version: string;
}

const routeModule: Module<RouteState, RootState> = {
    state: {
        isInitAuthRoute: false,
        isLogin: false,
    },
    getters: {
        isInitAuthRoute(state: RouteState): boolean {
            return state.isInitAuthRoute;
        },
        isLogin(state: RouteState): boolean {
            return state.isLogin;
        }
    },
    mutations: {
        setInitAuthRoute(state: RouteState, payload: boolean): void {
            state.isInitAuthRoute = payload;
        },
        setIsLogin(state: RouteState, payload: boolean): void {
            state.isLogin = payload;
        },
    },
    actions: {
        async updateInitAuthRoute(context: ActionContext<RouteState, RootState>, isInit: boolean): Promise<void> {
            const { commit } = context;
            commit('setInitAuthRoute', isInit);
        },
        async updateIsLogin(context: ActionContext<RouteState, RootState>, isLogin: boolean): Promise<void> {
            const { commit } = context;
            commit('setIsLogin', isLogin);
        },
    },
};


// 导出模块
export default routeModule;
