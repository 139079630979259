import {
    createRouter,
    createWebHashHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    Router
} from 'vue-router'
// import {createDynamicRouteGuard} from "@/router/permission";
import { constantRoutes, MenuRoutes } from "@/router/routes";
import { localStg } from '@/utils/storage';

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: [...constantRoutes, ...MenuRoutes],
});

/** 处理路由页面的权限 */
export async function createPermissionGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    console.log('createPermissionGuard');
    // 前置路由守卫
    router.beforeEach((to, from, next) => {
        console.log('beforeResolve');
        // next();
    });
    // 后置路由守卫
    router.afterEach(async (to, from) => {
        console.log('afterEach');
    });

    // 外链路由, 从新标签打开，返回上一个路由
    if (to.meta.href) {
        // window.open(to.meta.href);
        next({ path: from.fullPath, replace: true, query: from.query });
        return;
    }
}

export function createRouterGuard(router: Router) {
    // 开始 loadingBar
    // window.$loadingBar?.start();
    // 页面跳转权限处理
    router.beforeResolve(async (to, from, next) => {
        let isLoggedIn = Boolean(localStg.get('token'));
        // 如果目标路由需要进行登录，则检查用户是否已经登录
        if (to.meta.requiresAuth && !isLoggedIn) {
            next('/login')
        } else {
            next()
        }
    });

    // 后置守卫
    router.afterEach(to => {
        // 设置document title
        document.title =
            to.meta.title || process.env.VUE_APP_TITLE || '';
        // 结束 loadingBar
        // window.$loadingBar?.finish();
    });
}

createRouterGuard(router);

export default router
